* {
	margin: 0;
}

body {
	background-color: black;
}

.app {
	background-color: black;
	/* height: fit-content; */
	place-items: center;
}

.app__body {
	/* make background black later */
	background-color: black;
	margin: 0;
	display: grid;
	place-items: center;
	z-index: -1;
}

.app__container {
	display: flex;
	background-color: black;
	padding-top: 36px;
	width: 1024px;
	box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
	color: white;
	z-index: 50;
}
