.stats {
	flex: 0.3;
	display: flex;
	flex-direction: column;
	padding: 0px 30px 30px 30px;
}

.stats__container {
	flex-direction: column;
	flex: 1;
	background-color: #1e2023;
	border-radius: 5px;
	border: 1px solid #42494d;
}

.stats__content {
	font-size: 13px;
}

.stats__header {
	display: flex;
	font-size: 16px;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #42494d;
	padding: 20px 20px 10px 20px;
	font-weight: 500;
}

.stats-lists {
	height: 52px;
	border-top: 1px solid #42494d;
	padding: 4px 20px 0px 20px;
}
