.row {
	display: flex;
	justify-content: space-between;
	height: 60px;
	padding: 0 24px;
	align-items: center;
	cursor: pointer;
}
.row__chart {
	justify-items: right;
	padding: 0 10px;
}
.row:hover {
	background-color: #31363a;
}

.row__intro > h1 {
	font-size: 16px;
	font-weight: bold;
	padding-bottom: 2px;
}

.row__intro > p {
	font-size: 12px;
}

.row__numbers {
	text-align: end;
	font-weight: 500;
}

.row__price {
	padding-bottom: 4px;
}

.row__percentage {
	color: #5ac53b;
}
